@import "mixins";
/* COLOR PALETTE */
$primary: #121212;
$white: #FFFFFF;
$black: #212121;
$wax-flower: #FFC4AB;
/* MAP COLOR TO CLASS COLOR--VARIABLE
 * FIRST IS COLOR FROM :ROOT, SECOND IS FROM $COLOR-VAR
 */
$colors: (
    "--white": var(--white, $white),
    "--black": var(--black, $black),
    "--primary": var(--primary, $primary),
    "--wax-flower": var(--wax-flower, $wax-flower),
);
/* BREAKPOINTS */
$breakpoints: (
    "phone": 400px,
    "phone-wide": 480px,
    "phablet": 560px,
    "tablet-small": 640px,
    "tablet": 768px,
    "tablet-wide": 1024px,
    "desktop": 1248px,
    "desktop-wide": 1440px,
    "desktop-large": 1650px
)
!default;
/* GLOBAL SETTINGS */
$padding: 2.2rem;
$paddingResponsive: $padding * 3;