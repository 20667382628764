@import "../../styles/base/variables";

.bingo {
    width: 100vw;
    height: 100vh;
    padding-top: 5rem;
    @include flex;
    align-items: center;

    > svg {
        width: 14.3rem;
        height: auto;
        margin-bottom: 8rem;
    }

    &__board {
        width: 90vw;
    }

    &__btn {
        &-new-game {
            margin-top: 4rem;
        }

        &-instruction {
            margin-top: 3rem;
        }
    }
}