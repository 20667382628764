/* SET MEDIA QUERIES */
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
/* SET DISPLAY FLEX PROPERTY */
@mixin flex($direction: column, $wrap: nowrap) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
}
/* FONT SETUP */
@mixin font($weight, $family, $style: normal, $color: $primary) {
    font-weight: $weight;
    font-family: $family, sans-serif;
    font-style: $style;
    color: $color;
    -webkit-font-smoothing: antialiased;
}
/* NO SELECT SETUP */
@mixin userSelect($select: none) {
    -webkit-touch-callout: $select;
    -webkit-user-select: $select;
    /* Chrome all / Safari all */
    -moz-user-select: $select;
    /* Firefox all */
    -ms-user-select: $select;
    /* IE 10+ */
    user-select: $select;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}