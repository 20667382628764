@import "../../styles/base/variables";

.start {
    width: 100vw;
    height: 100vh;
    padding-top: 19rem;
    @include flex;
    align-items: center;
    justify-content: center;

    &__btn {
        &-game {
            width: 84vw;
            margin: 13rem 0 3rem;
        }

        &-highscore {
            margin-top: 3rem;
        }

        &-instruction {
            margin-top: 3rem;
        }
    }

    svg {
        width: 28rem;
        height: auto;
    }
}