@import "../../styles/base/variables";

*[role='grid'] {
    border-collapse: collapse;
    // width: 100%;
    @media (orientation: landscape) {
        width: 50vh;
    }
    @media (orientation: portrait) {
        width: 100%;
    }
    padding: 2rem;
}
@media (orientation: landscape) {
    body {
        flex-direction: row;
    }
}
@media (orientation: portrait) {
    body {
        flex-direction: column;
    }
}

*[role='gridcell'] {
    position: relative;
}

*[role='gridcell']:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.board {
    @include flex;
    align-items: center;

    > button {
        margin-top: 4.3rem;
        text-transform: uppercase;
        color: map-get($colors, "--white");
        border-style: initial;
        cursor: pointer;
        font-size: 1.2rem;
        letter-spacing: 0.257rem;
        background: transparent;
    }
}

.cell-contents {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0.2rem;
}

.cell-toggle {
    width: 100%;
    height: 100%;
    padding: 0.2rem;
    overflow: auto;
    background-color: map-get($colors, "--white");
    border-radius: 0.8rem;
    border: map-get($colors, "--white");
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    svg {
        width: 80%;
        height: auto;
    }
}

.cell-toggle[aria-pressed='true'] {
    background: map-get($colors, "--wax-flower");
    color: white;
    font-style: italic;
    box-shadow: inset -5px -5px 10px rgba(255, 225, 197, 0.5), inset 5px 5px 10px rgba(217, 167, 145, 0.5);
}

.cell-toggle[aria-disabled='true'] {
    cursor: default;
}

.cell-toggle:focus {
    outline: none;
    border: 0.5rem solid #1589ee;
}
@media only screen and (max-device-width : 480px) {
    .cell-toggle {
        font-size: 0.7rem;
    }
}