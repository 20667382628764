@import "../../styles/base/variables";

.btn {
    text-transform: uppercase;
    color: map-get($colors, "--white");
    border-style: initial;
    cursor: pointer;

    &--ghost {
        font-size: 1.2rem;
        letter-spacing: 0.257rem;
        background: transparent;
    }

    &--circle {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: map-get($colors, "--wax-flower");
        box-shadow: -5px -5px 10px rgba(255, 225, 197, 0.5), 5px 5px 10px rgba(217, 167, 145, 0.5);

        &:hover {
            background: linear-gradient(145deg, #e6b09a, #ffd2b7);
        }
    }

    &--neumorphism {
        width: 100%;
        height: 4.8rem;
        border-radius: 1.6rem;
        @include font(700, 'Raleway', $color: map-get($colors, "--white"));
        font-size: 1.8rem;
        text-shadow: -2px -2px 4px rgba(255, 225, 197, 0.5), 2px 2px 4px rgba(217, 167, 145, 0.5);
        letter-spacing: 0.225rem;
        background-color: map-get($colors, "--wax-flower");
        box-shadow: -5px -5px 10px rgba(255, 225, 197, 0.5), 5px 5px 10px rgba(217, 167, 145, 0.5);

        &:hover {
            background: linear-gradient(145deg, #e6b09a, #ffd2b7);
        }
    }
}