/* NOTE
html is set to 62.5% so that all the REM measurements are
based on 10px sizing. So basically 1.5rem = 15px :) */
html {
    font-size: 62.5%;
    @include userSelect;
}

body {
    margin: 0;
    @include font(300, 'Raleway');
    font-size: 1.5em;
    /* currently ems cause chrome bug misinterpreting rems on body element */
    line-height: 1.6;
    position: relative;
    scroll-behavior: smooth;
    background-color: map-get($colors, "--wax-flower");
}

body,
html {
    width: 100%;
    min-height: 100%;
}

main {
    position: relative;
    @include flex;
    width: 100%;
    height: 100%;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

*,
*:focus {
    outline: none;
}

*::-moz-focus-inner {
    border: 0;
}