@import "../../styles/base/variables";

:root {
    --color-background: #31037D;
    --axis-x: 1px;
    --axis-y: 1rem;
    --delay: 10;
    --color-black: #000;
    --color-white: #fff;
    --color-orange: #D49C3D;
    --color-red: #D14B3D;
    --color-violet: #CF52EB;
    --color-blue: #44A3F7;
    --color-green: #5ACB3C;
    --color-yellow: #DEBF40;
    --color-foreground: var(--color-white);
    --font-name: Righteous;
}

.congratulation {
    width: 100vw;
    height: 100vh;
    padding-top: 5rem;
    @include flex;
    align-items: center;
    justify-content: center;

    h1 {
        margin: 5rem 0;
        font-size: 2.1rem;
        font-weight: 700;
        color: map-get($colors, "--white");
    }

    p {
        margin-bottom: 13rem;
        font-size: 1.8rem;
        color: map-get($colors, "--white");
    }

    svg {
        width: 28rem;
        height: auto;
        stroke: $black;
    }

    &__rainbow {
        counter-reset: rainbow;
        position: relative;
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        &__layer {
            --text-color: var(--color-foreground);
            counter-increment: rainbow;
            font-size: 3rem;
            color: var(--text-color);
            text-shadow: -1px -1px 0 var(--color-black), 1px -1px 0 var(--color-black), -1px 1px 0 var(--color-black), 1px 1px 0 var(--color-black), 4px 4px 0 rgba(0, 0, 0, .2);
            animation: rainbow 1.5s ease-in-out infinite;
            @for $i from 1 through 7 {
                &:nth-child(#{$i}) {
                    animation-delay: calc(#{$i} / var(--delay) * 1s);
                    left: calc(var(--axis-x) * #{$i});
                    z-index: -#{$i * 10};
                }
            }

            &:not(:first-child) {
                position: absolute;
                top: 0;
            }

            &:nth-child(1) svg {
                fill: var(--color-white);
            }

            &:nth-child(2) svg {
                fill: var(--color-orange);
            }

            &:nth-child(3) svg {
                fill: var(--color-red);
            }

            &:nth-child(4) svg {
                fill: var(--color-violet);
            }

            &:nth-child(5) svg {
                fill: var(--color-blue);
            }

            &:nth-child(6) svg {
                fill: var(--color-green);
            }

            &:nth-child(7) svg {
                fill: var(--color-yellow);
            }
        }
    }
    @keyframes rainbow {
        0%,
        100% {
            transform: translatey(var(--axis-y));
        }

        50% {
            transform: translatey(calc(var(--axis-y) * -1));
        }
    }
}